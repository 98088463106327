import Markdown from "marked-react";
import ErrorBoundary from "./ErrorBoundry";

type Issue =
  | string
  | {
      [issue: string]: Issue[];
    };

type IssuesBoxProps = {
  title: string;
  issues: Issue[];
  icon?: JSX.Element;
};

function Issues({ issues }: { issues: Issue[] }) {
  return (
    <ErrorBoundary>
      <ul className="mb-0">
        {issues.map((issue: Issue, i) =>
          typeof issue === "object" && issue ? (
            Object.keys(issue).map((key: string) => (
              <li key={key}>
                <Markdown value={key} isInline={true} />
                <Issues issues={issue[key]} />
              </li>
            ))
          ) : (
            <li key={i}>
              <Markdown value={issue} isInline={true} />
            </li>
          )
        )}
      </ul>
    </ErrorBoundary>
  );
}

export default function IssuesBox({ title, icon, issues }: IssuesBoxProps) {
  return (
    <div className="my-4 rounded-lg border-slate-200 shadow p-4">
      <h2 className="mt-0 mb-4">
        {icon}
        {title}
      </h2>
      <Issues issues={issues} />
    </div>
  );
}
