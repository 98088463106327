import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, prevChildren: props.children };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.children !== state.prevChildren) {
      return {
        prevChildren: props.children,
        hasError: false,
      };
    }
    return null;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}