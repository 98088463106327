import React, { useEffect, useState } from "react";
import "./App.css";
import CodeEditor from "@uiw/react-textarea-code-editor";
import moment from 'moment';
import Markdown from 'marked-react';
import Navbar from "./Navbar";
import yaml from "js-yaml";
import IssuesBox from "./IssuesBox";
import {
  BugAntIcon,
  CheckBadgeIcon,
  PencilIcon,
  PencilSquareIcon,
  StarIcon,
} from "@heroicons/react/24/outline";

const defaultYaml = `
title: Your Project
version: 1.0.0
preparedBy: Roberts Bobbins
copyright: Business Name
date:
features:
  - A new feature
bugfixes:
  - A bug fixed
issues:
  - A known issue
notes:
`;

function App() {
  const [code, setCode] = useState<string>(defaultYaml);
  const [data, setData] = useState<any>({});
  const [error, setError] = useState<string>();

  useEffect(() => {
    try {
      setData(yaml.load(code) || {});
      setError(undefined);
    } catch (e: any) {
      setError(e.message as string || "Something went wrong parsing the YAML...");
      console.log(e);
    }
  }, [code]);

  useEffect(() => {
    document.title = `${data.title || "Unknown Project"} Release Notes v${data.version}`;
  }, [data.title, data.version]);

  return (
    <div className="flex flex-col h-screen max-h-screen print:max-h-max">
      <Navbar />
      <div className="flex flex-1 overflow-hidden print:overflow-visible">
        <div className="flex-1 flex flex-col print:hidden">
          <div className="flex-1 overflow-auto">
            <CodeEditor
              className="min-h-full"
              value={code}
              language="yaml"
              onChange={(env) => setCode(env.target.value)}
              padding={15}
              style={{
                fontSize: 16,
                backgroundColor: "#f5f5f5",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </div>
          {error && <pre className="whitespace-pre-wrap p-2">{error}</pre>}
        </div>
        <article className="flex-1 prose p-4 max-w-none overflow-auto print:w-screen print:overflow-visible">
          <h1 className="mb-2">
            {data.title} Release Notes{" "}
            <span className="bg-emerald-200 px-2 rounded-lg leading-relaxed">
              v{data.version}
            </span>
          </h1>
          <div className="flex flex-row my-4">
            <div className="flex-1">
              <div><b>Date:</b> {(data.date ? moment(data.date, "DD/MM/YYYY") : moment()).format("Do MMMM YYYY")}</div>
              {data.preparedBy && <div><b className="mb-0">Prepared By:</b> {data.preparedBy}</div>}
            </div>
            {data.notes && (
              <div className="flex-1 rounded-lg border-slate-200 shadow p-4">
                <h2 className="mt-0 mb-4"><PencilIcon className="h-6 w-6 inline-block mb-1 mr-2" /> Notes</h2>
                <p className="mb-0 whitespace-pre-wrap not-prose"><Markdown value={data.notes} isInline={true} /></p>
              </div>
            )}
          </div>
          
          {data.features && Array.isArray(data.features) && (
            <IssuesBox
              title="New Features"
              icon={<StarIcon className="h-6 w-6 inline-block mb-1 mr-2" />}
              issues={data.features}
            />
          )}
          {data.changes && Array.isArray(data.changes) && (
            <IssuesBox
              title="Changes"
              icon={<PencilSquareIcon className="h-6 w-6 inline-block mb-1 mr-2" />}
              issues={data.changes}
            />
          )}
          {data.bugfixes && Array.isArray(data.bugfixes) && (
            <IssuesBox
              title="Bug Fixes"
              icon={
                <CheckBadgeIcon className="h-6 w-6 inline-block mb-1 mr-2" />
              }
              issues={data.bugfixes}
            />
          )}
          {data.issues && Array.isArray(data.issues) && (
            <IssuesBox
              title="Known Issues"
              icon={<BugAntIcon className="h-6 w-6 inline-block mb-1 mr-2" />}
              issues={data.issues}
            />
          )}
          {data.copyright && <div className="mt-12 text-slate-500">Copyright &copy; <Markdown value={data.copyright} isInline={true} /></div>}
        </article>
      </div>
    </div>
  );
}

export default App;
